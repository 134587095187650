import React from "react"
import {Button} from '../components/Button'
import { Layout } from '../components/common/Layout'
import SEO from "../components/seo"
import Head from '../components/common/Head'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Head title="NotFoundTitle" />
    <Error>
      <h1>404</h1>
      <p> <FormattedMessage id="NotFound" /> </p>
      <Button to='/' round='true' big='true' primary='true'> <FormattedMessage id="NotFoundBtn" /> </Button>
    </Error>
  </Layout>
)

export default NotFoundPage

const Error = styled.div`
  background: #dbe2e7;
  height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: clamp(2rem, 6vw, 20rem);
    margin-bottom: 1rem;
  }

  p {
    font-size: clamp(1.4rem, 2vw, 8rem);
    margin-bottom: 2rem
  }
`
